import React, { useEffect } from 'react';

import { isKorean } from '@/util';
import { feGuideUrl } from '@/constant';

const FeGuideRedirection: React.FC = () => {
  useEffect(() => {
    window.location.href = isKorean ? feGuideUrl.KO : feGuideUrl.EN;
  });

  return <div />;
};

export default FeGuideRedirection;
